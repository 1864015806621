import { Link } from "react-router-dom";

export default function Addaddress({addaddress,setaddress,address}) {
 

 

  return (
    <>
      <div className="mt-10 sm:mt-10 w-3/4 m-auto">
        {/* <div className="md:grid md:grid-cols-3 md:gap-6"> */}
        <div className=" bg-gray-50 px-4 py-3 justify-between	grid-cols-5 flex text-right sm:px-6">
          <Link to="/">
            {" "}
            <button
              type="submit"
              className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-1"
            >
              All Address
            </button>
          </Link>
        </div>
        <div className="mt-5 md:col-span-2 md:mt-0 ">
          <form action="#" method="POST">
            <div className="overflow-hidden shadow sm:rounded-md">
              <div className="bg-white px-4 py-5 sm:p-6">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="first-name"
                      className="block text-xl font-medium text-gray-700"
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      onChange={(e) =>
                        setaddress({ ...address, name: e.target.value })
                      }
                      name="first-name"
                      id="first-name"
                      autoComplete="given-name"
                      // style={{boxShadow: "rgba(3, 102, 214, 0.3) 0px 0px 0px 3px"}}
                      className="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                invalid:border-pink-500 invalid:text-pink-600
                focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3  lg:col-span-3">
                    <label
                      htmlFor="postal-code"
                      className="block text-xl font-medium text-gray-700"
                    >
                      Contact no.
                    </label>
                    <input
                      type="number"
                      onChange={(e) =>
                        setaddress({ ...address, contact: e.target.value })
                      }
                      name="number"
                      id="first-name"
                      // style={{boxShadow: "rgba(3, 102, 214, 0.3) 0px 0px 0px 3px"}}
                      className="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                invalid:border-pink-500 invalid:text-pink-600
                focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-6 m-auto w-full  lg:col-span-3">
                    <label
                      htmlFor="street-address"
                      className="block text-xl font-medium text-gray-700"
                    >
                      Street
                    </label>
                    <input
                      type="text"
                      onChange={(e) =>
                        setaddress({ ...address, street: e.target.value })
                      }
                      name="street-address"
                      id="street-address"
                      autoComplete="street-address"
                      // style={{boxShadow: "rgba(3, 102, 214, 0.3) 0px 0px 0px 3px"}}
                      className="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                invalid:border-pink-500 invalid:text-pink-600
                focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-6  lg:col-span-3">
                    <label
                      htmlFor="city"
                      className="block text-xl font-medium text-gray-700"
                    >
                      City
                    </label>
                    <input
                      type="text"
                      onChange={(e) =>
                        setaddress({ ...address, city: e.target.value })
                      }
                      name="city"
                      id="city"
                      // style={{boxShadow: "rgba(3, 102, 214, 0.3) 0px 0px 0px 3px"}}
                      autoComplete="address-level2"
                      className="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                invalid:border-pink-500 invalid:text-pink-600
                focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                    <label
                      htmlFor="region"
                      className="block text-xl font-medium text-gray-700"
                    >
                      State / Province
                    </label>
                    <input
                      type="text"
                      onChange={(e) =>
                        setaddress({ ...address, state: e.target.value })
                      }
                      name="region"
                      id="region"
                      autoComplete="address-level1"
                      // style={{boxShadow: "rgba(3, 102, 214, 0.3) 0px 0px 0px 3px"}}
                      className="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                invalid:border-pink-500 invalid:text-pink-600
                focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3  lg:col-span-3">
                    <label
                      htmlFor="postal-code"
                      className="block text-xl font-medium text-gray-700"
                    >
                      ZIP / Postal code
                    </label>
                    <input
                      type="text"
                      onChange={(e) =>
                        setaddress({ ...address, postal: e.target.value })
                      }
                      name="postal-code"
                      id="postal-code"
                      autoComplete="postal-code"
                      // style={{boxShadow: "rgba(3, 102, 214, 0.3) 0px 0px 0px 3px"}}
                      className="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                invalid:border-pink-500 invalid:text-pink-600
                focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                    />
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 text-left sm:px-6"></div>
              <div className=" bg-gray-50 px-4 py-3 justify-between	grid-cols-5 text-right sm:px-6">
                <button
                  onClick={addaddress}
                  type="submit"
                  className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-1"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
