import React, { useCallback, useEffect, useState } from 'react'
import Addaddress from './Addaddress'
import Alladdress from './Alladdress'
import { Route, Routes } from 'react-router-dom'
import axios from 'axios'

const Main = () => {
  const [address, setaddress] = useState({
    name: "",
    contact: null,
    street: "",
    city: "",
    state: "",
    postal: "",
  });
  const [alladdress, setalladdress] = useState([]);
  const addaddress = useCallback((event) => {
    event.preventDefault();
    if(address.name===""||address.contact===""||address.street===""||address.city===""||address.state===""||address.postal===""){
      alert("all fields are required")
      return;
    }
    if(address.contact.length!==10){
      alert("contact should be of only 10 digit")
      return
    }
    axios
      .post("https://backend-server-address-task2.onrender.com/alladdress/addaddress", address)
      .then((d) => alert("address added successfully"))
      .catch((err) => console.error(err));

    getalldata()  
  });
  const getalldata = () => {
    axios
      .get("https://backend-server-address-task2.onrender.com/alladdress/")
      .then((r) => setalladdress(r.data.formaddress))
      .catch((err) => console.error(err));
  };
   
  useEffect(() => {
    getalldata()
  },[addaddress])
  return (
    <div>
      <Routes>
      <Route path={"/"} element={<Alladdress alladdress={alladdress}/>}></Route>
      <Route path='/addnewaddress' element={<Addaddress address={address} setaddress={setaddress} addaddress={addaddress}/>}></Route>
      </Routes>
      
    </div>
  )
}

export default Main
